import React, { useRef, useState, useEffect } from 'react'
import { get } from 'lodash'
import classNames from 'classnames'
import { loadScript } from 'utils/index'

import { Input } from '@collegebacker/backer-ui/ui'
import { USStateAbbreviation } from 'constants/us-states'
import styles from './InputAddressNew.module.scss'

let autoCompleteService: any

const GOOGLE_PLACES = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDJEbjtm72mjATqlYwd9ZYAKF9mAbRLHPw&libraries=places`

const AddressMarkerIcon = () => (
  <svg
    width="12"
    height="15"
    viewBox="0 0 12 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={styles.markerIcon}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 15C6 15 12 10.3846 12 5.76923C12 2.58297 9.31371 0 6 0C2.68629 0 0 2.58297 0 5.76923C0 10.3846 6 15 6 15ZM6 8C7.10457 8 8 7.10457 8 6C8 4.89543 7.10457 4 6 4C4.89543 4 4 4.89543 4 6C4 7.10457 4.89543 8 6 8Z"
    />
  </svg>
)

const handleScriptLoad = (
  inputRef: any,
  setApiLoaded: any,
  setPlaceOptions: (places: any) => void
) => {
  setApiLoaded(true)

  autoCompleteService = new (
    window as any
  ).google.maps.places.AutocompleteService(inputRef.current, {
    types: ['geocode'],
    componentRestrictions: { country: 'us' }
  })

  inputRef.current.addEventListener('input', async (event: any) => {
    const inputValue = event.target.value

    if (!inputValue) return

    const res = await autoCompleteService.getPlacePredictions(
      {
        input: inputValue,
        region: 'us',
        componentRestrictions: { country: 'us' }
      },
      async (_prediction: any, status: any) => {
        if (status !== 'OK') console.error('No results found')
      }
    )

    const options = res.predictions
      .map((prediction: any) => {
        return {
          description: prediction.description,
          placeId: prediction.place_id
        }
      })
      .slice(0, 4)

    setPlaceOptions(options)
  })
}

const mapAddressParts = (addressObject: any) => {
  const addressParts: any = {
    street_number: null,
    route: null,
    locality: null,
    sublocality_level_1: null,
    administrative_area_level_1: null,
    postal_code: null
  }
  const { address_components } = addressObject

  if (address_components) {
    address_components.forEach((addressComponent: any) => {
      const addressType = addressComponent.types[0]
      if (Object.keys(addressParts).includes(addressType)) {
        if (addressType === 'locality') {
          addressParts[addressType] = addressComponent.long_name
        } else if (addressType === 'sublocality_level_1') {
          addressParts['locality'] = addressComponent.long_name
        } else {
          addressParts[addressType] = addressComponent.short_name
        }
      }
    })
  }
  return addressParts
}

const handlePlaceSelect = async (
  addressObject: any,
  updateQuery: any,
  onSelect: any
) => {
  const query = addressObject.formatted_address
  updateQuery(query)
  const addressParts = mapAddressParts(addressObject)
  onSelect(addressParts)
}

export const InputAddressNew = ({
  autoFocus,
  disabled,
  label,
  helperText,
  name,
  onBlur,
  onChange,
  value,
  setAddress,
  onSelect,
  errorMessage,
  wrapperClassName
}: Props) => {
  /* ======= REFS ======= */

  const inputRef = useRef<HTMLInputElement>(null)
  const placeOptionsRef = useRef([] as HTMLButtonElement[])

  /* ======= STATE ======= */

  const [apiLoaded, setApiLoaded] = useState(false)
  const [placeOptions, setPlaceOptions] = useState([] as PlacesOptionsI[])
  const [placesService, setPlacesService] = useState(null as any)
  const [selectedPlaceId, setSelectedPlaceId] = useState(null as any)

  /* ======= HANDLERS ======= */

  const handleDropdownSelect = (optionId: string) => {
    setSelectedPlaceId(optionId)

    setPlaceOptions([])

    // focus on input
    if (inputRef && inputRef.current) inputRef.current.focus()
  }

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault()

      // focus on first item
      if (placeOptionsRef.current.length === 0) return

      const firstItem = placeOptionsRef.current[0]
      firstItem.focus()
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault()

      // focus on last item
      if (placeOptionsRef.current.length === 0) return

      const lastItem =
        placeOptionsRef.current[placeOptionsRef.current.length - 1]
      lastItem.focus()
    }
  }

  const handleDropdownButtonKeyDown = (
    event: React.KeyboardEvent<HTMLButtonElement>,
    index: number,
    placeId: string
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault()

      handleDropdownSelect(placeId)
    }

    if (event.key === 'ArrowDown') {
      event.preventDefault()

      if (index === placeOptionsRef.current.length - 1) {
        // focus on input
        if (inputRef && inputRef.current) inputRef.current.focus()
        return
      }

      // focus on next item
      const nextItem = placeOptionsRef.current[index + 1]
      nextItem.focus()
    }

    if (event.key === 'ArrowUp') {
      event.preventDefault()

      if (index === 0) {
        // focus on input
        if (inputRef && inputRef.current) inputRef.current.focus()
        return
      }

      // focus on previous item
      const previousItem = placeOptionsRef.current[index - 1]
      previousItem.focus()
    }
  }

  /* ======= EFFECTS ======= */

  useEffect(() => {
    if (selectedPlaceId) {
      const request = {
        placeId: selectedPlaceId,
        fields: ['address_components', 'formatted_address']
      }
      placesService.getDetails(request, (place: any, status: any) => {
        if (status === 'OK') {
          handlePlaceSelect(place, setAddress, onSelect)
        }
      })
    }
  }, [selectedPlaceId, placesService])

  useEffect(() => {
    if (typeof window === 'undefined' || apiLoaded) return

    const alreadyPresent = !!get(window, 'google.maps.places')
    const init = () => {
      handleScriptLoad(inputRef, setApiLoaded, setPlaceOptions)

      setPlacesService(
        new (window as any).google.maps.places.PlacesService(inputRef.current)
      )
    }

    if (alreadyPresent) {
      init()
    } else {
      loadScript(GOOGLE_PLACES, init)
    }
  }, [apiLoaded])

  /* ======= RENDER ======= */

  return (
    <div className={classNames(styles.wrapper, wrapperClassName)}>
      <Input
        autoFocus={autoFocus}
        disabled={disabled}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        value={value}
        errorMessage={errorMessage}
        label={label}
        helperText={helperText}
        ref={inputRef}
        noAutocomplete
        onKeyDown={handleInputKeyDown}
        maxLength={30}
      />

      <section
        className={classNames(styles.optionsDropdown, {
          [styles.dropdownVisible]: placeOptions.length > 0 && value !== ''
        })}
      >
        <span className={classNames('typo-app-body-caption', styles.caption)}>
          Select your address:
        </span>
        <ul>
          {placeOptions.map((option, i) => (
            <li key={i}>
              <button
                className={styles.item}
                onClick={() => handleDropdownSelect(option.placeId)}
                type="button"
                ref={(el) => {
                  if (el) placeOptionsRef.current[i] = el
                }}
                onKeyDown={(e) =>
                  handleDropdownButtonKeyDown(e, i, option.placeId)
                }
              >
                <AddressMarkerIcon />
                <span className="typo-app-body-main">{option.description}</span>
              </button>
            </li>
          ))}
        </ul>
      </section>
    </div>
  )
}

/* ======= TYPES ======= */

interface Props {
  autoFocus?: boolean
  label: string
  modifier?: string
  name: string
  value?: string
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onSelect?: (addressParts: {
    street_number: string
    route: string
    locality: string
    administrative_area_level_1: USStateAbbreviation
    postal_code: string
  }) => void
  setAddress: (arg0: string) => void
  helperText?: string
  errorMessage?: string
  disabled?: boolean
  wrapperClassName?: string
}

interface PlacesOptionsI {
  description: string
  placeId: string
}

export interface AddressParts {
  street_number: string
  route: string
  locality: string
  administrative_area_level_1: USStateAbbreviation
  postal_code: string
}
